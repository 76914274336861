import { render, staticRenderFns } from "./reporteResumenVenta.vue?vue&type=template&id=4504d2e9&scoped=true&"
import script from "./reporteResumenVenta.vue?vue&type=script&lang=js&"
export * from "./reporteResumenVenta.vue?vue&type=script&lang=js&"
import style0 from "./reporteResumenVenta.vue?vue&type=style&index=0&id=4504d2e9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4504d2e9",
  null
  
)

export default component.exports